import { useEffect, useState } from 'react';
import {
  AuthorizationService,
  authorizedUser,
  isLoggedIn,
} from '../../../auth/infrastructure/AuthorizationService';
import { NotFoundError } from '../../infrastructure/errors/NotFoundError';
import { UnauthorizedError } from '../../infrastructure/errors/UnauthorizedError';
import { NotificationManager } from '../shared/NotificationManager';
import { Routes, useNavigate } from './useNavigate';

let cacheTimestamp: number | undefined;

export const useUserAuthorization = (
  { redirect }: { redirect?: boolean } = { redirect: true },
) => {
  const isBeta = false;

  const { navigate } = useNavigate();

  const [loading, setLoading] = useState(!isLoggedIn());

  const refreshSession = async () => {
    cacheTimestamp = undefined;

    await AuthorizationService.getCurrentSession();
  };

  useEffect(() => {
    if (cacheTimestamp && Date.now() - cacheTimestamp < 1000 * 60 * 5) {
      return;
    }

    setLoading(!isLoggedIn());

    AuthorizationService.getCurrentSession()
      .catch((error) => {
        const isUnauthorizedError = error instanceof UnauthorizedError;
        const isNotFoundError = error instanceof NotFoundError;

        const handledError = isUnauthorizedError || isNotFoundError;

        const needToRedirect = redirect && handledError;

        if (needToRedirect) {
          if (isNotFoundError) {
            NotificationManager.error('No se encontró el usuario');
          }

          navigate(Routes.Auth);
        }

        if (!handledError) {
          console.error(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, redirect]);

  const logout = () => {
    AuthorizationService.logout().then(() => {
      cacheTimestamp = undefined;

      navigate(Routes.Auth, { replace: true });
    });
  };

  return {
    isBeta,
    loading,
    session: authorizedUser.get(),
    login: AuthorizationService.login,
    loginWithGoogle: AuthorizationService.loginWithGoogle,
    logout: logout,
    signIn: AuthorizationService.signIn,

    refreshSession,
  };
};
