import { config } from '../../bootstrap/infrastructure/config';
import { NotFoundError } from '../../shared/infrastructure/errors/NotFoundError';
import { UnauthorizedError } from '../../shared/infrastructure/errors/UnauthorizedError';
import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';
import { AuthorizedUser } from '../domain/AuthorizedUser';
import { User } from '@supabase/supabase-js';
import { migrateUserMetadata } from './migrations/UserMetadataMigrations';
import { Routes } from '../../shared/application/hooks/useNavigate';
import { observable, runInAction } from 'mobx';

export const authorizedUser = observable.box<AuthorizedUser | undefined>(
  undefined,
);

export const isLoggedIn = () => {
  return !!authorizedUser.get();
};

export const getAuthorizedUser = () => {
  const user = authorizedUser.get();

  if (!user) {
    throw new UnauthorizedError();
  }

  return user;
};

const migrateUser = async (userFromSupabase: User) => {
  const { needToMigrate, user } = migrateUserMetadata(userFromSupabase);

  if (needToMigrate) {
    await SupabaseClient().auth.updateUser({ data: user.user_metadata });
  }

  runInAction(() => {
    authorizedUser.set(AuthorizedUser.fromPrimitives(user));
  });
};

const getCurrentSession = async () => {
  const { data, error } = await SupabaseClient().auth.getUser();

  if (!data.user) {
    throw new UnauthorizedError();
  }

  if (error) {
    if (error.status === 401) {
      throw new UnauthorizedError();
    } else if (error.status === 404) {
      throw new NotFoundError();
    } else {
      throw error;
    }
  }

  await migrateUser(data.user);

  return authorizedUser.get();
};

const login = async (email: string, password: string) => {
  const { error, data } = await SupabaseClient().auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  if (!data.user) {
    throw new UnauthorizedError();
  }

  await migrateUser(data.user);
};

const loginWithGoogle = async (embed?: boolean) => {
  const url = new URLSearchParams();

  if (embed) {
    url.append('after-embed-login', 'true');
  }

  const redirectTo = `${config.url}${Routes.Auth}?${url.toString()}`;

  const { error } = await SupabaseClient().auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: redirectTo,
    },
  });

  if (error) {
    throw error;
  }

  await getCurrentSession();
};

const logout = async () => {
  const { error } = await SupabaseClient().auth.signOut();

  if (error) {
    throw error;
  }

  runInAction(() => {
    authorizedUser.set(undefined);
  });
};

const signIn = async (email: string, password: string) => {
  const { error, data } = await SupabaseClient().auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: config.url,
    },
  });

  if (error) {
    throw error;
  }

  if (!data.user) {
    throw new UnauthorizedError();
  }

  await migrateUser(data.user);
};

export const AuthorizationService = {
  getCurrentSession,
  login,
  loginWithGoogle,
  logout,
  signIn,
};
